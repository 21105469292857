import { Injectable, signal } from '@angular/core';
import { PlayerDto } from '../shared/models/player/PlayerDto';
import { SideNavigation } from '../shared/models/SideNavigation';
import { SidenavigationTypes } from '../constants/SidenavigationTypes';
import { PlayerGame } from '../shared/models/player-game/PlayerGame';
import { UserDto } from '../shared/models/user/UserDto';

@Injectable({
  providedIn: 'root'
})
export class SignalsService {
  onlinePlayers = signal<PlayerDto[]>([]);
  sideContentData = signal<SideNavigation>({
    data: {},
    type: SidenavigationTypes.DEFAULT
  });
  gameDetail = signal<PlayerGame>({} as PlayerGame);
  userDetails = signal<UserDto>({} as UserDto);
  loading = signal<boolean>(false);
}
